import React, { Component } from 'react';
class Health extends Component {
  render() {
    return (
      <div className="Health">
        App is healthy.
      </div>
    );
  }
}

export default Health;
